
















































import {Component, Vue} from 'vue-property-decorator';
import {resolveError} from '@/utils/notifications';
import AuthRepository from '@/repositories/AuthRepository';

@Component({name: 'Verification'})
export default class Verification extends Vue {
  isActivated: boolean = false;
  loading: boolean = false;

  message: string = '';

  mounted() {
    this.activateAccount();
  }

  async activateAccount() {
    try {
      this.loading = true;
      await AuthRepository.activateAccount(this.$route.params.token);
      this.message = 'SYSTEM_ACCOUNT_VERIFIED';
      this.isActivated = true;
    } catch (e) {
      this.isActivated = false;
      resolveError(e, 'activate');
      this.message = e.response.data.message;
    } finally {
      this.loading = false;
    }
  }
}
